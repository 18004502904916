import React, { Fragment } from "react";
import plus from "../../../assets/plus.png";
import minus from "../../../assets/minus.png";
import location from "../../../assets/location.jpg";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { supplierActions } from "../../../actions";
import { categoryActions } from "../../../actions";
import { getLocalStorage } from "../../../utils/helper/localStorage";
import { LOCAL_STORAGE_USER } from "../../../../constants";
import { set, useForm } from "react-hook-form";
import { CircularProgress, Dialog, Link, Tooltip } from "@mui/material";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import $ from "jquery";
import { supplierConstants } from "../../../constants";

export default function SupplierDetail({
  open,
  onClose,
  id,
  onOpenConfirmDelete,
  onUpdate,
  latitude,
  longtitude,
  openLocation,
  address,
  postalCode,
}) {
  const [isEdit, setIsEdit] = React.useState(false);
  const [isError, setIsError] = React.useState(true);
  const [requiredValue, setRequiredValue] = React.useState([]);
  const dispatch = useDispatch();
  const userLogin = getLocalStorage(LOCAL_STORAGE_USER);
  const supplier = useSelector((state) => state.supplier.get);
  const update = useSelector((state) => state.supplier.update);
  const bigCategory = useSelector((state) => state.category.getBigCategory);
  const medCategory = useSelector((state) => state.category.getMediumCategory);
  const detailCategory = useSelector(
    (state) => state.category.getDetailCategory
  );
  const [isAllTime, setIsAllTime] = React.useState(false);
  const [catLevel, setCatLevel] = React.useState([
    { level1: "", level2: "", level3: "" },
  ]);
  const [categoryLevel1, setCategoryLevel1] = React.useState([]);
  const [categoryLevel2, setCategoryLevel2] = React.useState([]);
  const [categoryLevel3, setCategoryLevel3] = React.useState([]);

  const [errorTime1, setErrorTime1] = React.useState(false);
  const [errorTime2, setErrorTime2] = React.useState(false);
  const [errorTime3, setErrorTime3] = React.useState(false);

  const fields = [
    "name",
    "address_postalcode",
    "company_working_at_phone_number",
    "address_detail",
    "company_phone_number",
    "company_fax",
    "business_start_hour",
    "business_end_hour",
    "rest_start_hour",
    "rest_end_hour",
    "rest_start_hour_2",
    "rest_end_hour_2",
    "is_regular_day_off_on_monday",
    "is_regular_day_off_on_thirsday",
    "is_regular_day_off_on_wednesday",
    "is_regular_day_off_on_tuesday",
    "is_regular_day_off_on_friday",
    "is_regular_day_off_on_saturday",
    "is_regular_day_off_on_sunday",
    "is_regular_day_off_on_holiday",
    "regular_day_off_note",
    "detail",
    "memo",
    "created_at",
    "latitude",
    "longtitude",
    "all_day_business_possible",
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    reset,
  } = useForm();

  const initData = async () => {
    if (id) {
      await dispatch(supplierActions.get(id));
    }
    await dispatch(categoryActions.getBigCategory());
    await dispatch(categoryActions.getMediumCategory());
    await dispatch(categoryActions.getDetailCategory());
  };

  React.useEffect(() => {
    if (open) {
      initData();
    }
  }, [open]);

  React.useEffect(() => {
    if (address) {
      setValue("address_detail", address);
    }
    if (postalCode) {
      setValue("address_postalcode", postalCode);
    } else {
      setValue("address_postalcode", "");
    }
  }, [address, postalCode]);

  React.useEffect(() => {
    if (bigCategory.items) {
      let categoryLevel1 = bigCategory.items.map((cat) => {
        return {
          value: cat.id,
          label: cat.name,
        };
      });
      categoryLevel1.unshift({ value: "", label: "大カテゴリ" });
      setCategoryLevel1(categoryLevel1);
    } else {
      setCategoryLevel1([{ value: "", label: "大カテゴリ" }]);
    }
    if (medCategory.items) {
      let categoryLevel2 = medCategory.items.map((cat) => {
        return {
          value: cat.id,
          label: cat.name,
          level1: cat.big_id,
        };
      });
      categoryLevel2.unshift({ value: "", label: "中カテゴリ" });
      setCategoryLevel2(categoryLevel2);
    } else {
      setCategoryLevel2([{ value: "", label: "中カテゴリ" }]);
    }
    if (detailCategory.items) {
      let categoryLevel3 = detailCategory.items.map((cat) => {
        return {
          value: cat.id,
          label: cat.name,
          level1: cat.big_id,
          level2: cat.medium_id,
        };
      });
      categoryLevel3.unshift({ value: "", label: "詳細" });
      setCategoryLevel3(categoryLevel3);
    } else {
      setCategoryLevel3([{ value: "", label: "詳細" }]);
    }
  }, [bigCategory, medCategory, detailCategory]);

  React.useEffect(() => {
    if (supplier.item) {
      supplier.item?.supplierData["all_day_business_possible"] === 1
        ? setIsAllTime(true)
        : setIsAllTime(false);
      fields.forEach((field) => {
        setValue(field, supplier.item?.supplierData[field]);
        if (
          field == "business_start_hour" &&
          supplier.item?.supplierData["business_start_hour"]
        ) {
          setValue(
            "business_start_hour",
            Moment(
              supplier.item?.supplierData["business_start_hour"],
              "HH:mm:ss"
            ).format("HH:mm")
          );
        }
        if (
          field == "business_end_hour" &&
          supplier.item?.supplierData["business_end_hour"]
        ) {
          setValue(
            "business_end_hour",
            Moment(
              supplier.item?.supplierData["business_end_hour"],
              "HH:mm:ss"
            ).format("HH:mm")
          );
        }
        if (
          field == "rest_start_hour" &&
          supplier.item?.supplierData["rest_start_hour"]
        ) {
          setValue(
            "rest_start_hour",
            Moment(
              supplier.item?.supplierData["rest_start_hour"],
              "HH:mm:ss"
            ).format("HH:mm")
          );
        }
        if (
          field == "rest_end_hour" &&
          supplier.item?.supplierData["rest_end_hour"]
        ) {
          setValue(
            "rest_end_hour",
            Moment(
              supplier.item?.supplierData["rest_end_hour"],
              "HH:mm:ss"
            ).format("HH:mm")
          );
        }
        if (
          field == "rest_start_hour_2" &&
          supplier.item?.supplierData["rest_start_hour_2"]
        ) {
          setValue(
            "rest_start_hour_2",
            Moment(
              supplier.item?.supplierData["rest_start_hour_2"],
              "HH:mm:ss"
            ).format("HH:mm")
          );
        }
        if (
          field == "rest_end_hour_2" &&
          supplier.item?.supplierData["rest_end_hour_2"]
        ) {
          setValue(
            "rest_end_hour_2",
            Moment(
              supplier.item?.supplierData["rest_end_hour_2"],
              "HH:mm:ss"
            ).format("HH:mm")
          );
        }
      });
      let category = supplier.item.cat?.map((cat) => {
        return {
          level1: cat.product_big_category,
          level2: cat.product_medium_category,
          level3: cat.product_detail,
        };
      });
      if (category.length > 0) {
        setCatLevel(category);
      }

      setRequiredValue({
        ...requiredValue,
        [`name`]: !!supplier.item.supplierData["name"],
        [`address_postalcode`]:
          !!supplier.item.supplierData["address_postalcode"],
        [`address_detail`]: !!supplier.item.supplierData["address_detail"],
      });

      if (
        requiredValue["name"] &&
        requiredValue["address_postalcode"] &&
        requiredValue["address_detail"]
      ) {
        setIsError(false);
      } else {
        setIsError(true);
      }
    }
  }, [supplier]);

  const handleEdit = async (data) => {
    if (isEdit) {
      if (catLevel.length != 1 || catLevel[0].level1 != "") {
        data.cat = catLevel.filter(
          (item) => !!item.level1 && item.level1 != ""
        );
      }
      if (!data.business_start_hour) {
        data.business_start_hour = null;
      }
      if (!data.business_end_hour) {
        data.business_end_hour = null;
      }
      if (!data.rest_start_hour) {
        data.rest_start_hour = null;
      }
      if (!data.rest_end_hour) {
        data.rest_end_hour = null;
      }
      if (!data.rest_start_hour_2) {
        data.rest_start_hour_2 = null;
      }
      if (!data.rest_end_hour_2) {
        data.rest_end_hour_2 = null;
      }
      if (isAllTime) {
        data.all_day_business_possible = 1;
      } else {
        data.all_day_business_possible = 0;
        data.rest_start_hour_2 = null;
        data.rest_end_hour_2 = null;
      }
      data.id = supplier.item.supplierData.id;
      data.admin_id = supplier.item.supplierData.admin_id;
      data.latitude = latitude;
      data.longtitude = longtitude;
      data.detail = data.detail ?? "";

      await dispatch(supplierActions.update(data));

      dispatch({ type: supplierConstants.UPDATE_SUCCESS, payload: null });
    }
  };

  React.useEffect(() => {
    if (update.item) {
      onUpdate();
      setIsEdit(!isEdit);
      resetCatLevel();
    }
  }, [update]);

  const handleDelete = () => {
    onOpenConfirmDelete();
  };

  const handleChangeRequiredValue = (e) => {
    if (e.target?.value) {
      setRequiredValue({
        ...requiredValue,
        [`${e.target.name}`]: true,
      });
    } else {
      setRequiredValue({
        ...requiredValue,
        [`${e.target.name}`]: false,
      });
    }
  };

  React.useEffect(() => {
    if (
      !requiredValue["name"] ||
      !requiredValue["address_postalcode"] ||
      !requiredValue["address_detail"]
    ) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [requiredValue]);

  const handleChangeCat = (e, index, level) => {
    const newCatLevel = catLevel.map((c, i) => {
      if (i === index) {
        if (level == 1) {
          return {
            ...c,
            level1: +e.target.value,
            level2: "",
            level3: "",
          };
        } else if (level == 2) {
          return {
            ...c,
            level2: +e.target.value,
            level3: "",
          };
        } else {
          return {
            ...c,
            level3: +e.target.value,
          };
        }
      } else {
        return c;
      }
    });
    setCatLevel(newCatLevel);
    $(function () {
      $("#bigCategory").each(function () {
        this.style.setProperty("color", "#000", "important");
      });
    });
  };

  const handleAddCategory = () => {
    if (isEdit) {
      setCatLevel([...catLevel, { level1: "", level2: "", level3: "" }]);
    }
  };

  const handledeleteCategory = (index) => {
    if (isEdit) {
      var newCat = catLevel.filter((ele, ind) => ind !== index);
      setCatLevel(newCat);
    }
  };

  const times = [
    { value: "", label: "未設定" },
    { value: "00:00:00", label: "00:00" },
    { value: "01:00:00", label: "01:00" },
    { value: "02:00:00", label: "02:00" },
    { value: "03:00:00", label: "03:00" },
    { value: "04:00:00", label: "04:00" },
    { value: "05:00:00", label: "05:00" },
    { value: "06:00:00", label: "06:00" },
    { value: "07:00:00", label: "07:00" },
    { value: "08:00:00", label: "08:00" },
    { value: "09:00:00", label: "09:00" },
    { value: "10:00:00", label: "10:00" },
    { value: "11:00:00", label: "11:00" },
    { value: "12:00:00", label: "12:00" },
    { value: "13:00:00", label: "13:00" },
    { value: "14:00:00", label: "14:00" },
    { value: "15:00:00", label: "15:00" },
    { value: "16:00:00", label: "16:00" },
    { value: "17:00:00", label: "17:00" },
    { value: "18:00:00", label: "18:00" },
    { value: "19:00:00", label: "19:00" },
    { value: "20:00:00", label: "20:00" },
    { value: "21:00:00", label: "21:00" },
    { value: "22:00:00", label: "22:00" },
    { value: "23:00:00", label: "23:00" },
  ];

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    onClose(false);
    dispatch({ type: supplierConstants.UPDATE_REQUEST });
    dispatch({ type: supplierConstants.GETBYID_REQUEST });
    reset();
    setErrorTime1(false);
    setErrorTime2(false);
    setErrorTime3(false);
    setIsError(false);
    resetCatLevel();
    setIsAllTime(false);
  };

  $(function () {
    $("select").on("change", function () {
      if ($(this).val() == "") $(this).css({ color: "#aaa" });
      else $(this).css({ color: "#000" });
      $($("[id=thirdCategory]")).each(function () {
        if ($(this).val() != "") $(this).css({ color: "#aaa" });
      });
    });
    $($("[id=bigCategory1]")).each(function () {
      if ($(this).val() != "") $(this).css({ color: "#000" });
      // else $(this).css({ color: "#000" });
    });
    $($("[id=secondCategory]")).each(function () {
      if ($(this).val() != "") $(this).css({ color: "#000" });
      // else $(this).css({ color: "#000" });
    });
    $($("[id=thirdCategory]")).each(function () {
      if ($(this).val() != "") $(this).css({ color: "#000" });
      // else $(this).css({ color: "#000" });
    });
    $($("[id=businessStartHour]")).each(function () {
      if ($(this).val() != "") $(this).css({ color: "#000" });
      // else $(this).css({ color: "#000" });
    });
    $($("[id=businessEndHour]")).each(function () {
      if ($(this).val() != "") $(this).css({ color: "#000" });
      // else $(this).css({ color: "#000" });
    });
    $($("[id=restStartHour]")).each(function () {
      if ($(this).val() != "") $(this).css({ color: "#000" });
      // else $(this).css({ color: "#000" });
    });
    $($("[id=restEndHour]")).each(function () {
      if ($(this).val() != "") $(this).css({ color: "#000" });
      // else $(this).css({ color: "#000" });
    });
    $($("[id=restStartHour2]")).each(function () {
      if ($(this).val() != "") $(this).css({ color: "#000" });
      // else $(this).css({ color: "#000" });
    });
    $($("[id=restEndHour2]")).each(function () {
      if ($(this).val() != "") $(this).css({ color: "#000" });
      // else $(this).css({ color: "#000" });
    });
  });

  const handleClickAway = () => {
    $(function () {
      if ($("button:contains('場所を選択')").length == 0) {
        handleClose();
        setIsEdit(false);
      }
    });
  };

  const handleChangeTime = (e) => {
    setValue(`${e.target.name}`, e.target?.value);

    if (
      getValues("business_start_hour") &&
      getValues("business_end_hour") &&
      getValues("business_start_hour") >= getValues("business_end_hour")
    ) {
      setErrorTime1(true);
    } else {
      setErrorTime1(false);
    }

    if (
      getValues("rest_start_hour") &&
      getValues("rest_end_hour") &&
      getValues("rest_start_hour") >= getValues("rest_end_hour")
    ) {
      setErrorTime2(true);
    } else {
      setErrorTime2(false);
    }

    if (
      getValues("rest_start_hour_2") &&
      getValues("rest_end_hour_2") &&
      getValues("rest_start_hour_2") >= getValues("rest_end_hour_2")
    ) {
      setErrorTime3(true);
    } else {
      setErrorTime3(false);
    }
  };

  const resetCatLevel = () => {
    setCatLevel([{ level1: "", level2: "", level3: "" }]);
  };

  return (
    <Dialog open={open} onClose={handleClose} className="z-40">
      <form>
        <div className="justify-center items-center flex fixed inset-0 z-40 outline-none focus:outline-none text-sm font-medium">
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="relative flex flex-col w-[900px] h-[90%] bg-white rounded-[10px] pb-[10px] overflow-y-auto pr-[20px]">
              {!supplier.item ||
              !bigCategory.items ||
              !medCategory.items ||
              !detailCategory.items ? (
                <div className="text-black text-[15px] font-normal ml-[50%] mt-[35%]">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  {isEdit ? (
                    <button
                      disabled={
                        errorTime1 || errorTime2 || errorTime3 || isError
                      }
                      type="button"
                      onClick={handleSubmit(handleEdit)}
                      className={`${
                        errorTime1 || errorTime2 || errorTime3 || isError
                          ? "bg-[#748bbe]"
                          : "bg-blue-900"
                      } absolute left-[706px] top-[27px] w-[61px] h-[18px] rounded-[50px] border border-blue-900 text-center text-white text-xs font-bold `}
                    >
                      保存
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEdit(true);
                      }}
                      className="absolute left-[706px] top-[27px] w-[61px] h-[18px] bg-blue-900 rounded-[50px] border border-blue-900 text-center text-white text-xs font-bold "
                    >
                      編集
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => handleDelete()}
                    className=" absolute left-[784px] top-[27px] w-[61px] h-[18px] bg-white rounded-[50px] border border-red-600 text-center text-red-600 text-xs font-bold "
                  >
                    削除
                  </button>
                  <div className=" ml-[35px] mt-[22px] text-black text-xl font-bold ">
                    仕入先詳細
                  </div>
                  <div className="flex flex-row  ml-[36px] mt-[30px] items-center">
                    <div className="w-[140px]">仕入先名</div>
                    <input
                      readOnly={!isEdit}
                      {...register("name", {
                        onChange: (e) => handleChangeRequiredValue(e),
                      })}
                      className="p-2 w-[670px] h-[35px] bg-white rounded-[10px] border border-stone-300 text-sm"
                      id=""
                      type="text"
                      placeholder="横浜ライト工業株式会社"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder = "横浜ライト工業株式会社")
                      }
                    />
                  </div>
                  <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[8px] border-b border-gray-200" />
                  <div className="flex flex-row ml-[36px] mt-[10px] items-center">
                    <div className="w-[140px]">所在地</div>
                    <input
                      readOnly={!isEdit}
                      {...register("address_postalcode", {
                        onChange: (e) => handleChangeRequiredValue(e),
                      })}
                      className="p-2 w-[291px] h-[35px] bg-white rounded-[10px] border border-stone-300 text-sm"
                      placeholder="〒240-0035"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "〒240-0035")}
                    />
                    <img
                      onClick={() => {
                        if (isEdit) {
                          openLocation(
                            supplier.item.supplierData.latitude,
                            supplier.item.supplierData.longtitude
                          );
                        }
                      }}
                      className="w-[30px] h-[30px] ml-[330px] cursor-pointer"
                      src={location}
                    />
                  </div>
                  <div className="flex flex-row ml-[176px] mt-[17px]">
                    <input
                      readOnly={!isEdit}
                      {...register("address_detail", {
                        onChange: (e) => handleChangeRequiredValue(e),
                      })}
                      className="p-2 w-[666px] h-[35px] bg-white rounded-[10px] border border-stone-300 text-sm"
                      placeholder="神奈川県横浜市保土ヶ谷区今井町870"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder =
                          "神奈川県横浜市保土ヶ谷区今井町870")
                      }
                    />
                  </div>
                  <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[8px] border-b border-gray-200" />
                  <div className="flex flex-row ml-[36px] mt-[8px] items-center">
                    <div className="w-[140px]">企業電話番号</div>
                    <input
                      readOnly={!isEdit}
                      {...register("company_phone_number", {
                        pattern: {
                          value: /^[0-9０-９]*$/,
                          message:
                            "有効な企業電話番号（英数字のみ）を入力してください",
                        },
                      })}
                      className="p-2 w-[666px] h-[35px] bg-white rounded-[10px] border border-stone-300 text-sm"
                      placeholder="03-1234-5678"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "03-1234-5678")}
                    />
                  </div>
                  {errors.company_phone_number && (
                    <div className="ml-[185px] w-fit text-red-500 mb-[15px] h-px">
                      <span>{errors.company_phone_number.message}</span>
                    </div>
                  )}
                  <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[8px] border-b border-gray-200" />
                  <div className="flex flex-row ml-[36px] mt-[8px] items-center">
                    <div className="w-[140px]">企業FAX番号</div>
                    <input
                      readOnly={!isEdit}
                      {...register("company_fax")}
                      className="p-2 w-[666px] h-[35px] bg-white rounded-[10px] border border-stone-300"
                      placeholder="03-1234-5678"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "03-1234-5678")}
                    />
                  </div>
                  <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[8px] border-b border-gray-200" />
                  <div className="flex flex-row ml-[36px] mt-[8px] items-center">
                    <div className="w-[140px]">営業時間</div>
                    <div className="flex flex-row items-center">
                      {/* <select
                        id="businessStartHour"
                        disabled={!isEdit}
                        name="business_start_hour"
                        {...register("business_start_hour", {
                          onChange: (e) => handleChangeTime(e),
                        })}
                        className="p-1 w-40 h-8 bg-white rounded-[10px] border border-stone-300"
                      >
                        {times.map((item, i) => {
                          return (
                            <option
                              key={item.value + "_" + i}
                              className="text-gray-900 dark:text-gray-300"
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </select> */}
                      <input
                        disabled={!isEdit}
                        className="p-2 w-40 h-8 bg-white rounded-[10px] border border-stone-300"
                        list="business_start_hour"
                        type="text"
                        maxLength={5}
                        autoComplete="off"
                        role="combobox"
                        name="business_start_hour"
                        {...register(
                          "business_start_hour",
                          {
                            maxLength: 5,
                            minLength: 5,
                            pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                          },
                          {
                            onChange: (e) => handleChangeTime(e),
                          }
                        )}
                        onBlur={async () => {
                          await trigger("business_start_hour");
                        }}
                        onChange={(e) => handleChangeTime(e)}
                        placeholder="未設定"
                      />
                      <datalist
                        id="business_start_hour"
                        className="absolute bg-white overflow-y-auto h-[300px]"
                      >
                        <option value="00:00"></option>
                        <option value="00:30"></option>
                        <option value="01:00"></option>
                        <option value="01:30"></option>
                        <option value="02:00"></option>
                        <option value="02:30"></option>
                        <option value="03:00"></option>
                        <option value="03:30"></option>
                        <option value="04:00"></option>
                        <option value="04:30"></option>
                        <option value="05:00"></option>
                        <option value="05:30"></option>
                        <option value="06:00"></option>
                        <option value="06:30"></option>
                        <option value="07:00"></option>
                        <option value="07:30"></option>
                        <option value="08:00"></option>
                        <option value="08:30"></option>
                        <option value="09:00"></option>
                        <option value="09:30"></option>
                        <option value="10:00"></option>
                        <option value="10:30"></option>
                        <option value="11:00"></option>
                        <option value="11:30"></option>
                        <option value="12:00"></option>
                        <option value="12:30"></option>
                        <option value="13:00"></option>
                        <option value="13:30"></option>
                        <option value="14:00"></option>
                        <option value="14:30"></option>
                        <option value="15:00"></option>
                        <option value="15:30"></option>
                        <option value="16:00"></option>
                        <option value="16:30"></option>
                        <option value="17:00"></option>
                        <option value="17:30"></option>
                        <option value="18:00"></option>
                        <option value="18:30"></option>
                        <option value="19:00"></option>
                        <option value="19:30"></option>
                        <option value="20:00"></option>
                        <option value="20:30"></option>
                        <option value="21:00"></option>
                        <option value="21:30"></option>
                        <option value="22:00"></option>
                        <option value="22:30"></option>
                        <option value="23:00"></option>
                        <option value="23:30"></option>
                      </datalist>
                      <div className="text-black text-sm  ml-[13px] font-normal ">
                        〜
                      </div>
                      {/* <select
                        id="businessEndHour"
                        disabled={!isEdit}
                        name="business_end_hour"
                        {...register("business_end_hour", {
                          onChange: (e) => handleChangeTime(e),
                        })}
                        className="p-1 w-40 h-8 bg-white rounded-[10px] ml-[13px] border border-stone-300"
                      >
                        {times.map((item, i) => {
                          return (
                            <option
                              key={item.value + "_" + i}
                              className="text-gray-900 dark:text-gray-300"
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </select> */}
                      <input
                        disabled={!isEdit}
                        className="p-2 w-40 h-8 bg-white rounded-[10px] border border-stone-300 ml-[10px]"
                        list="business_end_hour"
                        type="text"
                        maxLength={5}
                        autoComplete="off"
                        role="combobox"
                        name="business_end_hour"
                        {...register(
                          "business_end_hour",
                          {
                            maxLength: 5,
                            minLength: 5,
                            pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                          },
                          {
                            onChange: (e) => handleChangeTime(e),
                          }
                        )}
                        onBlur={async () => {
                          await trigger("business_end_hour");
                        }}
                        onChange={(e) => handleChangeTime(e)}
                        placeholder="未設定"
                      />
                      <datalist
                        id="business_end_hour"
                        className="absolute bg-white overflow-y-auto h-[300px]"
                      >
                        <option value="00:00"></option>
                        <option value="00:30"></option>
                        <option value="01:00"></option>
                        <option value="01:30"></option>
                        <option value="02:00"></option>
                        <option value="02:30"></option>
                        <option value="03:00"></option>
                        <option value="03:30"></option>
                        <option value="04:00"></option>
                        <option value="04:30"></option>
                        <option value="05:00"></option>
                        <option value="05:30"></option>
                        <option value="06:00"></option>
                        <option value="06:30"></option>
                        <option value="07:00"></option>
                        <option value="07:30"></option>
                        <option value="08:00"></option>
                        <option value="08:30"></option>
                        <option value="09:00"></option>
                        <option value="09:30"></option>
                        <option value="10:00"></option>
                        <option value="10:30"></option>
                        <option value="11:00"></option>
                        <option value="11:30"></option>
                        <option value="12:00"></option>
                        <option value="12:30"></option>
                        <option value="13:00"></option>
                        <option value="13:30"></option>
                        <option value="14:00"></option>
                        <option value="14:30"></option>
                        <option value="15:00"></option>
                        <option value="15:30"></option>
                        <option value="16:00"></option>
                        <option value="16:30"></option>
                        <option value="17:00"></option>
                        <option value="17:30"></option>
                        <option value="18:00"></option>
                        <option value="18:30"></option>
                        <option value="19:00"></option>
                        <option value="19:30"></option>
                        <option value="20:00"></option>
                        <option value="20:30"></option>
                        <option value="21:00"></option>
                        <option value="21:30"></option>
                        <option value="22:00"></option>
                        <option value="22:30"></option>
                        <option value="23:00"></option>
                        <option value="23:30"></option>
                      </datalist>
                      <input
                        id="all_day_business_possible"
                        disabled={!isEdit}
                        name="all_day_business_possible"
                        {...register("all_day_business_possible")}
                        type="checkbox"
                        className="p-2 w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[20px]"
                        onChange={() => setIsAllTime(!isAllTime)}
                        value="1"
                      />
                      <div className="text-zinc-800 text-sm font-medium ml-[4px] leading-[18px]">
                        24時間営業
                      </div>
                    </div>
                  </div>
                  {(errors?.business_start_hour?.type === "pattern" ||
                    errors?.business_end_hour?.type === "pattern") && (
                    <p
                      className="ml-[185px] w-fit text-red-500 mb-[15px] h-px"
                      role="alert"
                    >
                      無効な時間です。有効な時間の例: 10:10
                    </p>
                  )}
                  {(errors?.business_start_hour?.type === "minLength" ||
                    errors?.business_end_hour?.type === "minLength") && (
                    <p
                      className="ml-[185px] w-fit text-red-500 mb-[15px] h-px"
                      role="alert"
                    >
                      無効な時間です。有効な時間の例: 10:10
                    </p>
                  )}
                  {errorTime1 && (
                    <div className="ml-[185px] w-fit text-red-500 mb-[15px] h-px">
                      <span>終了時間を開始時間より設定してください</span>
                    </div>
                  )}
                  <div className="flex flex-row ml-[36px] mt-[8px] items-center">
                    <div className="w-[140px]">休憩時間（任意）</div>
                    <div className="flex flex-row items-center">
                      {/* <select
                        id="restStartHour"
                        disabled={!isEdit}
                        name="rest_start_hour"
                        {...register("rest_start_hour", {
                          onChange: (e) => handleChangeTime(e),
                        })}
                        className="p-1 w-40 h-8 bg-white rounded-[10px] border border-stone-300"
                      >
                        {times.map((item, i) => {
                          return (
                            <option
                              key={item.value + "_" + i}
                              className="text-gray-900 dark:text-gray-300"
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </select> */}
                      <input
                        disabled={!isEdit}
                        className="p-2 w-40 h-8 bg-white rounded-[10px] border border-stone-300"
                        list="rest_start_hour"
                        type="text"
                        maxLength={5}
                        autoComplete="off"
                        role="combobox"
                        name="rest_start_hour"
                        {...register(
                          "rest_start_hour",
                          {
                            maxLength: 5,
                            minLength: 5,
                            pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                          },
                          {
                            onChange: (e) => handleChangeTime(e),
                          }
                        )}
                        onBlur={async () => {
                          await trigger("rest_start_hour");
                        }}
                        onChange={(e) => handleChangeTime(e)}
                        placeholder="未設定"
                      />
                      <datalist
                        id="rest_start_hour"
                        className="absolute bg-white overflow-y-auto h-[300px]"
                      >
                        <option value="00:00"></option>
                        <option value="00:30"></option>
                        <option value="01:00"></option>
                        <option value="01:30"></option>
                        <option value="02:00"></option>
                        <option value="02:30"></option>
                        <option value="03:00"></option>
                        <option value="03:30"></option>
                        <option value="04:00"></option>
                        <option value="04:30"></option>
                        <option value="05:00"></option>
                        <option value="05:30"></option>
                        <option value="06:00"></option>
                        <option value="06:30"></option>
                        <option value="07:00"></option>
                        <option value="07:30"></option>
                        <option value="08:00"></option>
                        <option value="08:30"></option>
                        <option value="09:00"></option>
                        <option value="09:30"></option>
                        <option value="10:00"></option>
                        <option value="10:30"></option>
                        <option value="11:00"></option>
                        <option value="11:30"></option>
                        <option value="12:00"></option>
                        <option value="12:30"></option>
                        <option value="13:00"></option>
                        <option value="13:30"></option>
                        <option value="14:00"></option>
                        <option value="14:30"></option>
                        <option value="15:00"></option>
                        <option value="15:30"></option>
                        <option value="16:00"></option>
                        <option value="16:30"></option>
                        <option value="17:00"></option>
                        <option value="17:30"></option>
                        <option value="18:00"></option>
                        <option value="18:30"></option>
                        <option value="19:00"></option>
                        <option value="19:30"></option>
                        <option value="20:00"></option>
                        <option value="20:30"></option>
                        <option value="21:00"></option>
                        <option value="21:30"></option>
                        <option value="22:00"></option>
                        <option value="22:30"></option>
                        <option value="23:00"></option>
                        <option value="23:30"></option>
                      </datalist>
                      <div className="text-black text-sm  ml-[13px] font-normal ">
                        〜
                      </div>
                      {/* <select
                        id="restEndHour"
                        disabled={!isEdit}
                        name="rest_end_hour"
                        {...register("rest_end_hour", {
                          onChange: (e) => handleChangeTime(e),
                        })}
                        className="p-1 w-40 h-8 bg-white rounded-[10px] ml-[13px] border border-stone-300"
                      >
                        {times.map((item, i) => {
                          return (
                            <option
                              key={item.value + "_" + i}
                              className="text-gray-900 dark:text-gray-300"
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </select> */}
                      <input
                        disabled={!isEdit}
                        className="p-2 w-40 h-8 bg-white rounded-[10px] border border-stone-300 ml-[10px]"
                        list="rest_end_hour"
                        type="text"
                        maxLength={5}
                        autoComplete="off"
                        role="combobox"
                        name="rest_end_hour"
                        {...register(
                          "rest_end_hour",
                          {
                            maxLength: 5,
                            minLength: 5,
                            pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                          },
                          {
                            onChange: (e) => handleChangeTime(e),
                          }
                        )}
                        onBlur={async () => {
                          await trigger("rest_end_hour");
                        }}
                        onChange={(e) => handleChangeTime(e)}
                        placeholder="未設定"
                      />
                      <datalist
                        id="rest_end_hour"
                        className="absolute bg-white overflow-y-auto h-[300px]"
                      >
                        <option value="00:00"></option>
                        <option value="00:30"></option>
                        <option value="01:00"></option>
                        <option value="01:30"></option>
                        <option value="02:00"></option>
                        <option value="02:30"></option>
                        <option value="03:00"></option>
                        <option value="03:30"></option>
                        <option value="04:00"></option>
                        <option value="04:30"></option>
                        <option value="05:00"></option>
                        <option value="05:30"></option>
                        <option value="06:00"></option>
                        <option value="06:30"></option>
                        <option value="07:00"></option>
                        <option value="07:30"></option>
                        <option value="08:00"></option>
                        <option value="08:30"></option>
                        <option value="09:00"></option>
                        <option value="09:30"></option>
                        <option value="10:00"></option>
                        <option value="10:30"></option>
                        <option value="11:00"></option>
                        <option value="11:30"></option>
                        <option value="12:00"></option>
                        <option value="12:30"></option>
                        <option value="13:00"></option>
                        <option value="13:30"></option>
                        <option value="14:00"></option>
                        <option value="14:30"></option>
                        <option value="15:00"></option>
                        <option value="15:30"></option>
                        <option value="16:00"></option>
                        <option value="16:30"></option>
                        <option value="17:00"></option>
                        <option value="17:30"></option>
                        <option value="18:00"></option>
                        <option value="18:30"></option>
                        <option value="19:00"></option>
                        <option value="19:30"></option>
                        <option value="20:00"></option>
                        <option value="20:30"></option>
                        <option value="21:00"></option>
                        <option value="21:30"></option>
                        <option value="22:00"></option>
                        <option value="22:30"></option>
                        <option value="23:00"></option>
                        <option value="23:30"></option>
                      </datalist>
                    </div>
                  </div>
                  {(errors?.rest_start_hour?.type === "pattern" ||
                    errors?.rest_end_hour?.type === "pattern") && (
                    <p
                      className="ml-[185px] w-fit text-red-500 mb-[15px] h-px"
                      role="alert"
                    >
                      無効な時間です。有効な時間の例: 10:10
                    </p>
                  )}
                  {(errors?.rest_start_hour?.type === "minLength" ||
                    errors?.rest_end_hour?.type === "minLength") && (
                    <p
                      className="ml-[185px] w-fit text-red-500 mb-[15px] h-px"
                      role="alert"
                    >
                      無効な時間です。有効な時間の例: 10:10
                    </p>
                  )}
                  {errorTime2 && (
                    <div className="ml-[185px] w-fit text-red-500 mb-[15px] h-px">
                      <span>終了時間を開始時間より設定してください</span>
                    </div>
                  )}
                  {isAllTime && (
                    <>
                      <div className="flex flex-row ml-[36px] mt-[8px] items-center">
                        <div className="w-[140px]">休憩時間２（任意）</div>
                        <div className="flex flex-row items-center">
                          {/* <select
                            id="restStartHour2"
                            disabled={!isEdit}
                            name="rest_start_hour_2"
                            {...register("rest_start_hour_2", {
                              onChange: (e) => handleChangeTime(e),
                            })}
                            className="p-1 w-40 h-8 bg-white rounded-[10px] border border-stone-300"
                          >
                            {times.map((item, i) => {
                              return (
                                <option
                                  key={item.value + "_" + i}
                                  className="text-gray-900 dark:text-gray-300"
                                  value={item.value}
                                >
                                  {item.label}
                                </option>
                              );
                            })}
                          </select> */}
                          <input
                            disabled={!isEdit}
                            className="p-2 w-40 h-8 bg-white rounded-[10px] border border-stone-300"
                            list="rest_start_hour_2"
                            type="text"
                            maxLength={5}
                            autoComplete="off"
                            role="combobox"
                            name="rest_start_hour_2"
                            {...register(
                              "rest_start_hour_2",
                              {
                                maxLength: 5,
                                minLength: 5,
                                pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                              },
                              {
                                onChange: (e) => handleChangeTime(e),
                              }
                            )}
                            onBlur={async () => {
                              await trigger("rest_start_hour_2");
                            }}
                            onChange={(e) => handleChangeTime(e)}
                            placeholder="未設定"
                          />
                          <datalist
                            id="rest_start_hour_2"
                            className="absolute bg-white overflow-y-auto h-[300px]"
                          >
                            <option value="00:00"></option>
                            <option value="00:30"></option>
                            <option value="01:00"></option>
                            <option value="01:30"></option>
                            <option value="02:00"></option>
                            <option value="02:30"></option>
                            <option value="03:00"></option>
                            <option value="03:30"></option>
                            <option value="04:00"></option>
                            <option value="04:30"></option>
                            <option value="05:00"></option>
                            <option value="05:30"></option>
                            <option value="06:00"></option>
                            <option value="06:30"></option>
                            <option value="07:00"></option>
                            <option value="07:30"></option>
                            <option value="08:00"></option>
                            <option value="08:30"></option>
                            <option value="09:00"></option>
                            <option value="09:30"></option>
                            <option value="10:00"></option>
                            <option value="10:30"></option>
                            <option value="11:00"></option>
                            <option value="11:30"></option>
                            <option value="12:00"></option>
                            <option value="12:30"></option>
                            <option value="13:00"></option>
                            <option value="13:30"></option>
                            <option value="14:00"></option>
                            <option value="14:30"></option>
                            <option value="15:00"></option>
                            <option value="15:30"></option>
                            <option value="16:00"></option>
                            <option value="16:30"></option>
                            <option value="17:00"></option>
                            <option value="17:30"></option>
                            <option value="18:00"></option>
                            <option value="18:30"></option>
                            <option value="19:00"></option>
                            <option value="19:30"></option>
                            <option value="20:00"></option>
                            <option value="20:30"></option>
                            <option value="21:00"></option>
                            <option value="21:30"></option>
                            <option value="22:00"></option>
                            <option value="22:30"></option>
                            <option value="23:00"></option>
                            <option value="23:30"></option>
                          </datalist>
                          <div className="text-black text-sm  ml-[13px] font-normal ">
                            〜
                          </div>
                          {/* <select
                            id="restEndHour2"
                            disabled={!isEdit}
                            name="rest_end_hour_2"
                            {...register("rest_end_hour_2", {
                              onChange: (e) => handleChangeTime(e),
                            })}
                            className="p-1 w-40 h-8 bg-white rounded-[10px] ml-[13px] border border-stone-300"
                          >
                            {times.map((item, i) => {
                              return (
                                <option
                                  key={item.value + "_" + i}
                                  className="text-gray-900 dark:text-gray-300"
                                  value={item.value}
                                >
                                  {item.label}
                                </option>
                              );
                            })}
                          </select> */}
                          <input
                            disabled={!isEdit}
                            className="p-2 w-40 h-8 bg-white rounded-[10px] border border-stone-300 ml-[10px]"
                            list="rest_end_hour_2"
                            type="text"
                            maxLength={5}
                            autoComplete="off"
                            role="combobox"
                            name="rest_end_hour_2"
                            {...register(
                              "rest_end_hour_2",
                              {
                                maxLength: 5,
                                minLength: 5,
                                pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                              },
                              {
                                onChange: (e) => handleChangeTime(e),
                              }
                            )}
                            onBlur={async () => {
                              await trigger("rest_end_hour_2");
                            }}
                            onChange={(e) => handleChangeTime(e)}
                            placeholder="未設定"
                          />
                          <datalist
                            id="rest_end_hour_2"
                            className="absolute bg-white overflow-y-auto h-[300px]"
                          >
                            <option value="00:00"></option>
                            <option value="00:30"></option>
                            <option value="01:00"></option>
                            <option value="01:30"></option>
                            <option value="02:00"></option>
                            <option value="02:30"></option>
                            <option value="03:00"></option>
                            <option value="03:30"></option>
                            <option value="04:00"></option>
                            <option value="04:30"></option>
                            <option value="05:00"></option>
                            <option value="05:30"></option>
                            <option value="06:00"></option>
                            <option value="06:30"></option>
                            <option value="07:00"></option>
                            <option value="07:30"></option>
                            <option value="08:00"></option>
                            <option value="08:30"></option>
                            <option value="09:00"></option>
                            <option value="09:30"></option>
                            <option value="10:00"></option>
                            <option value="10:30"></option>
                            <option value="11:00"></option>
                            <option value="11:30"></option>
                            <option value="12:00"></option>
                            <option value="12:30"></option>
                            <option value="13:00"></option>
                            <option value="13:30"></option>
                            <option value="14:00"></option>
                            <option value="14:30"></option>
                            <option value="15:00"></option>
                            <option value="15:30"></option>
                            <option value="16:00"></option>
                            <option value="16:30"></option>
                            <option value="17:00"></option>
                            <option value="17:30"></option>
                            <option value="18:00"></option>
                            <option value="18:30"></option>
                            <option value="19:00"></option>
                            <option value="19:30"></option>
                            <option value="20:00"></option>
                            <option value="20:30"></option>
                            <option value="21:00"></option>
                            <option value="21:30"></option>
                            <option value="22:00"></option>
                            <option value="22:30"></option>
                            <option value="23:00"></option>
                            <option value="23:30"></option>
                          </datalist>
                        </div>
                      </div>
                      {(errors?.rest_start_hour_2?.type === "pattern" ||
                        errors?.rest_end_hour_2?.type === "pattern") && (
                        <p
                          className="ml-[185px] w-fit text-red-500 mb-[15px] h-px"
                          role="alert"
                        >
                          無効な時間です。有効な時間の例: 10:10
                        </p>
                      )}
                      {(errors?.rest_start_hour_2?.type === "minLength" ||
                        errors?.rest_end_hour_2?.type === "minLength") && (
                        <p
                          className="ml-[185px] w-fit text-red-500 mb-[15px] h-px"
                          role="alert"
                        >
                          無効な時間です。有効な時間の例: 10:10
                        </p>
                      )}
                      {errorTime3 && (
                        <div className="ml-[185px] w-fit text-red-500 mb-[15px] h-px">
                          <span>終了時間を開始時間より設定してください</span>
                        </div>
                      )}
                    </>
                  )}
                  <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[8px] border-b border-gray-200" />
                  <div className="flex flex-row ml-[36px] mt-[8px]">
                    <div className="w-[140px]">定休日</div>
                    <input
                      disabled={!isEdit}
                      value="1"
                      name="is_regular_day_off_on_monday"
                      {...register("is_regular_day_off_on_monday")}
                      type="checkbox"
                      className="w-3.5 h-3.5 bg-blue-900 rounded-sm mt-[8px]"
                    />
                    <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                      月
                    </div>
                    <input
                      disabled={!isEdit}
                      value="1"
                      name="is_regular_day_off_on_tuesday"
                      {...register("is_regular_day_off_on_tuesday")}
                      type="checkbox"
                      className="w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[13px] mt-[8px]"
                    />
                    <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                      火
                    </div>
                    <input
                      disabled={!isEdit}
                      value="1"
                      name="is_regular_day_off_on_wednesday"
                      {...register("is_regular_day_off_on_wednesday")}
                      type="checkbox"
                      className="w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[13px] mt-[8px]"
                    />
                    <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                      水
                    </div>
                    <input
                      disabled={!isEdit}
                      value="1"
                      name="is_regular_day_off_on_thirsday"
                      {...register("is_regular_day_off_on_thirsday")}
                      type="checkbox"
                      className="w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[13px] mt-[8px]"
                    />
                    <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                      木
                    </div>
                    <input
                      disabled={!isEdit}
                      value="1"
                      name="is_regular_day_off_on_friday"
                      {...register("is_regular_day_off_on_friday")}
                      type="checkbox"
                      className="w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[13px] mt-[8px]"
                    />
                    <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                      金
                    </div>
                    <input
                      disabled={!isEdit}
                      value="1"
                      name="is_regular_day_off_on_saturday"
                      {...register("is_regular_day_off_on_saturday")}
                      type="checkbox"
                      className="w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[13px] mt-[8px]"
                    />
                    <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                      土
                    </div>
                    <input
                      disabled={!isEdit}
                      value="1"
                      name="is_regular_day_off_on_sunday"
                      {...register("is_regular_day_off_on_sunday")}
                      type="checkbox"
                      className="w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[13px] mt-[8px]"
                    />
                    <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                      日
                    </div>
                    <input
                      disabled={!isEdit}
                      value="1"
                      name="is_regular_day_off_on_holiday"
                      {...register("is_regular_day_off_on_holiday")}
                      type="checkbox"
                      className="w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[13px] mt-[8px]"
                    />
                    <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                      祝
                    </div>
                  </div>
                  <div className="flex flex-row ml-[36px] items-center">
                    <div className="w-[140px]">定休日備考</div>
                    <input
                      readOnly={!isEdit}
                      name="regular_day_off_note"
                      {...register("regular_day_off_note")}
                      className="p-2 mt-[9px] w-[666px] h-[35px] bg-white rounded-[10px] border border-stone-300 text-sm placeholder:font-normal"
                      placeholder="土曜は隔週"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "土曜は隔週")}
                    />
                  </div>
                  <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[8px] border-b border-gray-200" />

                  {catLevel &&
                    catLevel.map((catItem, index) => {
                      return (
                        <Fragment key={catItem.level1 + "_" + index}>
                          <div className="flex flex-row ml-[36px] mt-[8px] items-center">
                            <div>{index == 0 ? "取扱品目" : ""}</div>
                            <select
                              id="bigCategory1"
                              disabled={!isEdit}
                              value={catItem.level1}
                              onChange={(e) => handleChangeCat(e, index, 1)}
                              className={`${
                                index == 0 ? " ml-[83px]" : " ml-[140px]"
                              } w-40 h-8 rounded-[10px]  border border-stone-300 placeholder:font-normal`}
                            >
                              {categoryLevel1.map((item, i) => {
                                return (
                                  <option
                                    value={item.value}
                                    key={item.value + "_" + i}
                                  >
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <select
                              id="secondCategory"
                              disabled={!isEdit}
                              value={catItem.level2}
                              onChange={(e) => handleChangeCat(e, index, 2)}
                              className="w-40 h-8 ml-[13px] rounded-[10px] border border-stone-300 placeholder:font-normal"
                            >
                              {categoryLevel2.map((item, i) => {
                                if (
                                  item.level1 == catItem.level1 ||
                                  item.value == ""
                                ) {
                                  return (
                                    <option
                                      value={item.value}
                                      key={item.value + "_" + i}
                                    >
                                      {item.label}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                            <select
                              id="thirdCategory"
                              disabled={!isEdit}
                              value={catItem.level3}
                              onChange={(e) => handleChangeCat(e, index, 3)}
                              className="w-40 h-8 ml-[13px] rounded-[10px] border border-stone-300 placeholder:font-normal"
                            >
                              {categoryLevel3.map((item, i) => {
                                if (
                                  (item.level1 == catItem.level1 &&
                                    item.level2 == catItem.level2) ||
                                  item.value == ""
                                ) {
                                  return (
                                    <option
                                      value={item.value}
                                      key={item.value + "_" + i}
                                    >
                                      {item.label}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                            {index == 0 && (
                              <Fragment>
                                <img
                                  onClick={() => handleAddCategory()}
                                  className="w-[20px] h-[20px] ml-[13px] cursor-pointer"
                                  src={plus}
                                />
                                <div
                                  onClick={() => handleAddCategory()}
                                  className="text-black text-xs font-normal ml-[4px]"
                                >
                                  取扱品目を追加する
                                </div>
                              </Fragment>
                            )}
                            {index != 0 && (
                              <img
                                onClick={() => handledeleteCategory(index)}
                                className="w-[20px] h-[20px] ml-[13px] cursor-pointer"
                                src={minus}
                              ></img>
                            )}
                          </div>
                        </Fragment>
                      );
                    })}
                  <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[8px] border-b border-gray-200" />
                  <div className="flex flex-row ml-[36px] mt-[8px]">
                    <div className="w-[140px]">詳細</div>
                    <textarea
                      readOnly={!isEdit}
                      name="detail"
                      {...register("detail", {
                        maxLength: {
                          value: 1400,
                          message: "Invalid maxlength",
                        },
                      })}
                      className="p-2 w-[666px] h-[67px] bg-white rounded-[10px] border border-stone-300 placeholder: font-normal"
                      placeholder="1400字以内&#10;営業時間は変動する可能性がございます"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder =
                          "1400字以内\n営業時間は変動する可能性がございます")
                      }
                    />
                  </div>
                  {errors.detail && (
                    <div className="ml-[185px] w-fit text-red-500 mb-[15px] h-px">
                      <span>{errors.detail.message}</span>
                    </div>
                  )}
                  <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[17px] border-b border-gray-200" />
                  <div className="flex flex-row ml-[36px] mt-[8px]">
                    <div className="w-[140px]">備考</div>
                    <textarea
                      readOnly={!isEdit}
                      name="memo"
                      {...register("memo", {
                        maxLength: {
                          value: 1400,
                          message: "Invalid maxlength",
                        },
                      })}
                      className="p-2 w-[666px] h-[67px] bg-white rounded-[10px] border border-stone-300"
                      placeholder="備考"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "備考")}
                    />
                  </div>
                  {errors.memo && (
                    <div className="ml-[185px] w-fit text-red-500 mb-[15px] h-px">
                      <span>{errors.memo.message}</span>
                    </div>
                  )}
                  <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[17px] border-b border-gray-200" />
                  <div className="flex flex-row ml-[36px] mt-[17px]">
                    <div className="w-[140px]">アカウント登録日</div>
                    <div className="text-black text-sm font-normal ">
                      {Moment(supplier.item?.supplierData.created_at).format(
                        "YYYY/MM/DD"
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row ml-[36px] mt-[20px] items-center">
                    <div className="w-[140px]">アカウント登録者ID</div>
                    <div className=" text-black text-sm font-normal ">
                      {/* <Tooltip title={userLogin.name} arrow>
                        <span>
                          {userLogin.name.length <= 20
                            ? userLogin.name
                            : userLogin.name.substring(0, 20) + "..."}
                        </span>
                      </Tooltip> */}
                      <Tooltip title={"100" + userLogin?.id} arrow>
                        <span className="text-black text-sm font-normal">
                          {"100" + userLogin?.id}
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[17px] border-b border-gray-200" />
                  <div className="flex flex-row ml-[36px] mt-[17px]">
                    <div className="w-[160px]">価格表アップロード履歴</div>
                  </div>
                  <div className="flex flex-row ml-[50px] mt-[17px]">
                    <div className="text-black text-sm font-normal ">
                    {supplier.item?.supplierData.supplier_price_lists &&
                    supplier.item?.supplierData.supplier_price_lists.map((fileItem, index) => {
                      return (
                        <div>
                          <Link className="text-[#6792ef]" key={index} target="_blank" rel="noopener noreferrer" href={fileItem.file_s3_link}>{Moment(fileItem.create_at).format("YYYY/MM/DD hh:mm") + " " + fileItem.file_name}</Link>
                        </div>
                      );
                    })}
                    </div>
                  </div>

                  <div className="flex flex-row ml-[340px] mt-[40px]">
                    <button
                      type="button"
                      // className="w-[193px] h-[39px] bg-white bg-opacity-60 rounded-[50px] border border-black border-opacity-20 text-stone-300 text-xl font-bold hover:bg-neutral-100 focus:outline-none focus:ring focus:bg-neutral-100"
                      className="w-[193px] h-[39px] bg-white/opacity-60 rounded-[50px] border border-black/opacity-20 text-stone-400 text-xl font-bold focus:outline-none focus:ring hover:bg-neutral-100 focus:bg-neutral-100"
                      onClick={() => {
                        setIsEdit(false);
                        setIsAllTime(false);
                        onClose();
                        dispatch({ type: supplierConstants.UPDATE_REQUEST });
                        dispatch({ type: supplierConstants.GETBYID_REQUEST });
                        setErrorTime1(false);
                        setErrorTime2(false);
                        setErrorTime3(false);
                        setIsError(false);
                        resetCatLevel();
                      }}
                    >
                      戻る
                    </button>
                  </div>
                </>
              )}
            </div>
          </ClickAwayListener>
        </div>
      </form>
    </Dialog>
  );
}
